import { NOTE_CONTACT, TAG_CONTACT, GROUP_CONTACT } from 'services/gpl/contact'

export const MESSAGE = `
    updatedAt
    createdAt
    sentAt
    message
    photo
    outbound
    status
    conversationId
    id
    read
    errorMessage
    timeToNextMessage
    user {
        firstName
        lastName
        photo
        id
    }
    contact {
        firstName
        lastName
        id
        phone
        email
        status
        optOutDate
    }
    campaign {
        id
    }
    attachment {
        serviceUrl
        contentType
        filename
    }
    tpInputMessage {
        id
        campaignName,
        stepName,
        tpName
    }
    status
    provider
    hasVcfAttached
`
export const MESSAGE_IN_CONVO = `
    createdAt
    updatedAt
    message
    status
    conversationId
    id
    errorMessage
    user {
        firstName
        lastName
        photo
        id
    }
    read
`
export const CONVERSATION_WITHOUT_LIMIT = `
    id
    contact {
        id
        email
        firstName
        lastName
        phone
        notes {${NOTE_CONTACT}}
        tags {${TAG_CONTACT}}
        contactGroups {${GROUP_CONTACT}}
        status
        createdAt
    }
    contactId
    creatorId
    updatedAt
    lastMessageCreatedAt
    messages { ${MESSAGE} }
    name
    messagesCount
    lastMessage {
        message
        updatedAt
        createdAt
    }
`

export const CONVERSATION = `
    id
    contact {
        id
        email
        firstName
        lastName
        phone
        status
        optOutDate
        createdAt
        tags {${TAG_CONTACT}}
        contactGroups {${GROUP_CONTACT}}
    }
    contactId
    creatorId
    updatedAt
    lastMessageCreatedAt
    conversationLabel
    messages(limit: 10) { ${MESSAGE_IN_CONVO} }
    name
    read
    messagesCount
    lastMessage {
        message
        updatedAt
        createdAt
    }
    spam
`

export const CONVERSATIONS_COMBINED = `
    key
    id
    contact  {
        id
        email
        firstName
        lastName
        phone
        status
        optOutDate
        notes {${NOTE_CONTACT}}
        organization {
            id
        }
    }
    updatedAt
    lastMessageCreatedAt
    conversationLabel
    read
    lastMessage {
        id
        message
        updatedAt
        createdAt
    }
    name
    campaign {
        id
        name
        contacts {
            id
            firstName
            lastName
        }
    }
    spam
`
export const MESSAGES_METRICS = `
    averageResponseTime
    contactCreationDate
    linksClickedRate
    responseRate
`
