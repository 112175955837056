import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Models
import { Chat, BubbleMessage } from 'types/api/chat.model'
import {
	Conversation,
	ConversationList,
	Message,
} from 'types/api/message.model'
// GQL
import {
	ICreateMessageMutation,
	ISendMessageMutation,
	ISearchMessage,
	IMetricsMessageFields,
	IMetricsMessage,
	ICreateConversationMutation,
	ReadMessageMutation,
	SearchListMessageQuery,
	FetchConversationFieldsQuery,
	GetConversationFieldsQuery,
} from './messages.types'
import {
	MESSAGE,
	MESSAGES_METRICS,
	CONVERSATION,
	CONVERSATION_WITHOUT_LIMIT,
	CONVERSATIONS_COMBINED,
} from './messages.gpl'

export type MessageFields = {
	input: ICreateMessageMutation
}
export type ConversationFields = {
	input: ICreateConversationMutation
}

export const FETCH_CONVERSATIONS = gql`
	query fetchConversations($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
		fetchConversations(startDate: $startDate, endDate: $endDate) {
			${CONVERSATION}
		}
	}
`

export const LIST_CONVERSATIONS = gql`
	query listConversations($limit: Int, $offset: Int, $spam: Boolean) {
		listConversations(limit: $limit, offset: $offset, spam: $spam) {
			${CONVERSATIONS_COMBINED}
		}
		totalConversations(spam: $spam)
	}
`

export const FETCH_COMBINED_CONVERSATIONS = gql`
	query fetchCombinedConversations($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
		fetchCombinedConversations(startDate: $startDate, endDate: $endDate) {
			${CONVERSATIONS_COMBINED}
		}
	}
`

export const LIST_SELECTED_MESSAGES = gql`
	query listMessages($limit: Int, $conversationId: ID) {
		listMessages(limit: $limit, conversationId: $conversationId) {
			${MESSAGE}
		}
	}
`
class MessagesService {
	private CREATE_CONVERSATION = gql`
		mutation createConversation($input: CreateConversationInput!) {
			createConversation(input: $input) {
				${CONVERSATION_WITHOUT_LIMIT}
			}
		}
	`
	private CREATE_MESSAGE = gql`
		mutation createMessage($input: CreateMessageInput!) {
			createMessage(input: $input) {
				${MESSAGE}
			}
		}
	`

	private UPDATE_SPAM_CONVERSATION = gql`
		query updateSpamConversation($id: ID!) {
			updateSpamConversation(id: $id) {
				${CONVERSATION}
			}
		}
	`

	private POLL_MESSAGE = gql`
		subscription {
			pollMessage {
				response {
					${MESSAGE}
				}
			}
		}
	`

	private POLL_CONTACT_MESSAGE = gql`
		subscription {
			pollContactMessage {
				response {
					message {
						updatedAt
						createdAt
						sentAt
						message
						photo
						outbound
						status
						conversationId
						id
						read
						errorMessage
						timeToNextMessage
						user {
							firstName
							lastName
							photo
							id
						}
						campaign {
							id
						}
						attachment {
							serviceUrl
							contentType
							filename
						}
						tpInputMessage {
							id
							campaignName
							stepName
							tpName
						}
					}
					contact {
						firstName
						lastName
						id
						phone
						email
						status
						optOutDate
					}
				}
			}
		}
	`

	private SEND_MESSAGE = gql`
		mutation SendMessage($input: SendMessageInput!) {
			sendMessage(input: $input) {
				message
				id
			}
		}
	`

	private SEARCH_MESSAGE = gql`
		query searchConversation($conversationId: ID!, $searchText: String!) {
			searchConversation(conversationId: $conversationId, searchText: $searchText) {
				${MESSAGE}
			}
		}
	`
	private GET_CONVERSATION = gql`
		query getConversation($id: ID!) {
			getConversation(id: $id) {
				${CONVERSATION}
			}
		}
	`

	private READ_MESSAGE = gql`
	mutation ReadMessage($input: ReadMessageInput!) {
		readMessage(input: $input) {
			${CONVERSATION_WITHOUT_LIMIT}
		}
	}`

	private FETCH_METRICS_MESSAGE = gql`
		query fetchConversationMetrics(
			$startDate: ISO8601DateTime!,
			$endDate: ISO8601DateTime!,
			$conversationId: ID!
		) {
			fetchConversationMetrics(
				startDate: $startDate,
				endDate: $endDate,
				conversationId: $conversationId
			) {
				${MESSAGES_METRICS}
			}
		}
	`
	private SEARCH_LIST_MESSAGE = gql`
		query listMessages($conversationId: ID, $query: String, $limit: Int, $offset: Int) {
			listMessages(conversationId: $conversationId, query: $query, limit: $limit, offset: $offset) {
				${MESSAGE}
			}
		}
	`

	listConversations = async (
		fields?: FetchConversationFieldsQuery
	): Promise<ConversationList> => {
		return await ApiService.client
			.query({
				query: LIST_CONVERSATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return {
					conversations: response.data!.listConversations,
					totalConversations: response.data!.totalConversations,
				}
			})
	}

	readMessage = async (fields: ReadMessageMutation): Promise<Chat[]> => {
		return await ApiService.client
			.mutate<{ readMessage: Chat[] }, { input: ReadMessageMutation }>({
				mutation: this.READ_MESSAGE,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data.readMessage
			})
	}

	createMessage = async (fields: MessageFields): Promise<Message> => {
		return await ApiService.client
			.mutate<{ createMessage: Message }, MessageFields>({
				mutation: this.CREATE_MESSAGE,
				variables: fields,
			})
			.then(async res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data.createMessage
			})
	}

	updateSpamConversation = async (
		input: GetConversationFieldsQuery
	): Promise<Conversation> => {
		return await ApiService.client
			.query({
				query: this.UPDATE_SPAM_CONVERSATION,
				variables: {
					id: input.conversationId,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.updateSpamConversation
			})
	}

	pollMessage = (next: (data: { message: Message }) => void) => {
		return ApiService.client
			.subscribe({
				query: this.POLL_MESSAGE,
			})
			.subscribe({
				next: ({ data }) => {
					if (data && data.pollMessage && data.pollMessage.response) {
						next({ message: data.pollMessage.response })
					}
				},
				error: e => console.error('[Error] Polling message: ', e),
			})
	}

	pollContactMessage = (next: (data: { message: Message }) => void) => {
		return ApiService.client
			.subscribe({
				query: this.POLL_CONTACT_MESSAGE,
			})
			.subscribe({
				next: ({ data }) => {
					if (
						data &&
						data.pollContactMessage &&
						data.pollContactMessage.response &&
						data.pollContactMessage.response.message
					) {
						next({
							message: {
								...data.pollContactMessage.response.message,
								contact: data.pollContactMessage.response.contact,
							},
						})
					}
				},
				error: e => console.error('[Error] Polling contact message: ', e),
			})
	}

	sendMessage = async (fields: ISendMessageMutation): Promise<Message> => {
		return await ApiService.client
			.mutate<{ sendMessage: Message }, { input: ISendMessageMutation }>({
				mutation: this.SEND_MESSAGE,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data.sendMessage
			})
	}

	createConversation = async (
		fields: ConversationFields
	): Promise<Conversation> => {
		return await ApiService.client
			.mutate<{ createConversation: Conversation }, ConversationFields>({
				mutation: this.CREATE_CONVERSATION,
				variables: fields,
			})
			.then(async res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.createConversation
			})
	}

	private GET_MESSAGES = gql`
		query conversations {
			listConversations {
				${CONVERSATION_WITHOUT_LIMIT}
			}
		}
	`

	private GET_MESSAGE = gql`
		query getMessage($id: ID!) {
			getMessage(id: $id) {
				id
				message
				hasVcfAttached
			}
		}
	`

	getMessages = async (): Promise<Chat[]> => {
		return await ApiService.client
			.query<{ listConversations: Chat[] | null }>({
				query: this.GET_MESSAGES,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.listConversations || []
			})
	}

	getMessage = async (id: string = ''): Promise<BubbleMessage> => {
		return await ApiService.client
			.query({
				query: this.GET_MESSAGE,
				variables: { id },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getMessage
			})
	}

	searchMessage = async (input: ISearchMessage): Promise<Message[]> => {
		return await ApiService.client
			.query({
				query: this.SEARCH_MESSAGE,
				variables: {
					conversationId: input.conversationId,
					searchText: input.searchText,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.searchConversation
			})
	}

	getConversation = async (
		input: GetConversationFieldsQuery
	): Promise<Conversation> => {
		return await ApiService.client
			.query({
				query: this.GET_CONVERSATION,
				variables: {
					id: input.conversationId,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getConversation
			})
	}

	getMessageMetrics = async (
		input: IMetricsMessageFields
	): Promise<IMetricsMessage> => {
		return await ApiService.client
			.query({
				query: this.FETCH_METRICS_MESSAGE,
				variables: {
					startDate: input.startDate,
					endDate: input.endDate,
					conversationId: input.conversationId,
				},
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.fetchConversationMetrics
			})
	}

	searchListMessage = async (
		input: SearchListMessageQuery
	): Promise<Message[]> => {
		return await ApiService.client
			.query({
				query: this.SEARCH_LIST_MESSAGE,
				variables: {
					conversationId: input.conversationId,
					query: input.query,
					limit: input.limit,
					offset: input.offset,
				},
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.listMessages
			})
	}
	//FETCH MESSAGES
	// fetchConversations = (
	// 	conversationOffset: number,
	// 	next: (data: { fetchConversations: Chat[] }) => void,
	// 	unknown?: boolean
	// ) => {
	// 	let variables: {
	// 		limit: number
	// 		conversationOffset: number
	// 		unknown?: boolean
	// 	} = {
	// 		limit: 10,
	// 		conversationOffset,
	// 	}

	// 	if (unknown) {
	// 		variables['unknown'] = unknown
	// 	}

	// 	return ApiService.client
	// 		.watchQuery({
	// 			query: FETCH_CONVERSATIONS,
	// 			pollInterval: MESSAGES_POLL_INTERVAL_MS,
	// 			variables: variables,
	// 		})
	// 		.subscribe({
	// 			next: ({ data }) => {
	// 				next(data)
	// 			},
	// 			error: e => console.error(e),
	// 		})
	// }

	fetchConversations = async (
		fields?: FetchConversationFieldsQuery
	): Promise<Conversation[]> => {
		return await ApiService.client
			.query({
				query: FETCH_CONVERSATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.fetchConversations
			})
	}

	fetchConversationsCombined = async (
		fields?: FetchConversationFieldsQuery
	): Promise<Conversation[]> => {
		return await ApiService.client
			.query({
				query: FETCH_COMBINED_CONVERSATIONS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.fetchCombinedConversations
			})
	}
}

export const getSelectedMessages = (
	conversationId: string,
	limit: number,
	next: (data: { listMessages: Message[] }) => void
) => {
	return ApiService.client
		.query({
			query: LIST_SELECTED_MESSAGES,
			variables: {
				limit: limit,
				conversationId: parseInt(conversationId),
			},
		})
		.then(response => {
			if (!response || !response.data) {
				throw new Error()
			}
			next(response.data)
		})
}

export default new MessagesService()
