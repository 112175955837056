import { createAsyncThunk } from '@reduxjs/toolkit'
import ConversationService from 'services/conversation'
import MessagesService from 'services/messages'
export const createConversation = createAsyncThunk(
	'messages/create/conversation',
	MessagesService.createConversation
)

export const createMessage = createAsyncThunk(
	'messages/create',
	MessagesService.createMessage
)

export const fetchConversations = createAsyncThunk(
	'message/get/conversations',
	MessagesService.fetchConversations
)

export const listConversations = createAsyncThunk(
	'message/get/listConversations',
	MessagesService.listConversations
)

export const fetchCombinedConversations = createAsyncThunk(
	'message/get/combimedConversations',
	MessagesService.fetchConversationsCombined
)
export const getConversation = createAsyncThunk(
	'message/get/conversation',
	MessagesService.getConversation
)

export const getMessages = createAsyncThunk(
	'messages/get',
	MessagesService.getMessages
)
export const getMessage = createAsyncThunk(
	'message/get',
	MessagesService.getMessage
)

export const searchMessage = createAsyncThunk(
	'messages/search',
	MessagesService.searchMessage
)

export const getMessageMetrics = createAsyncThunk(
	'messages/metrics',
	MessagesService.getMessageMetrics
)

export const sendMessage = createAsyncThunk(
	'messages/send',
	MessagesService.sendMessage
)

export const readMessage = createAsyncThunk(
	'messages/read',
	MessagesService.readMessage
)

export const searchListMessage = createAsyncThunk(
	'messages/search/list',
	MessagesService.searchListMessage
)

export const updateSpamConversation = createAsyncThunk(
	'messages/updateSpamConversation',
	MessagesService.updateSpamConversation
)

export const getConversationLink = createAsyncThunk(
	'messages/getConversationLink',
	ConversationService.getConversationLink
)
